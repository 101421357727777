/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import moduleName from "module"

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  url,
  canonical,
  keywords,
}: {
  description: string
  lang: string
  meta: any
  title: string
  image: string
  url: string
  canonical: string
  keywords: string
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogImage = image || site.siteMetadata.image
  const siteUrl = url || site.siteMetadata.siteUrl
  const fbId = 591961685090445
  const fbDomain = `ghblqfl7q80sbra142gt2dqq9mrnlw`
  const googleVerification = `hApk8Oyjy6kht391m_HnEtCxFUg2hSH_WGI3c`
  const metaKeywords = keywords || "" //キーワードがない場合は空文字

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: fbDomain,
        },
        {
          name: `google-site-verification`,
          content: googleVerification,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          name: "keywords",
          content: metaKeywords,
        },
        {
          property: `fb:app_id`,
          content: fbId,
        },
        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:img`,
          content: ogImage,
        },
      ].concat(meta)}
      link={[
        {
          rel: "canonical",
          href: canonical || siteUrl,
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  title: "",
  lang: `ja`,
  meta: [],
  description: `TAMではecbeing、shopifyをはじめ業界最多10種類のECプラットフォームをご利用いただけるほか、大規模なリソース確保を行うことが可能です。制作実績と経験に基づいた課題を解決するカート選定を行い、構築から運用までをワンストップでご依頼いただけます。`,
  image: "",
  url: "",
  canonical: "",
  keywords: "",
}

export default SEO
